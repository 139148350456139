<template>
  <div ref="sendSMS" class="sendSMS">
    <div class="sendSMSItem">
      <div class="sItemLabel">发送对象:</div>
      <el-input
        type="textarea"
        :rows="3"
        placeholder="请输入手机号，多个手机号用，分隔，例：13400000001，13400000002"
        v-model="smsIpone"
        :disabled="fileName == '' ? false : true"
      >
      </el-input>
    </div>
    <el-button type="primary" class="save" @click="imporT">批量{{ $t('button.import') }}</el-button>
    <div class="sendSMSItem">
      <div class="sItemLabel2">模板:</div>
      <el-select v-model="template" placeholder="请选择" @change="setChange">
        <el-option :label="labelSMs" :value="''" :key="''"></el-option>
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.templateName"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="submit">
      <el-button class="save2" type="primary" @click="addTemplate">新建模板</el-button>
      <el-button class="cancel" @click="templateManage">模板管理</el-button>
    </div>
    <div class="sendSMSItem">
      <div class="sItemLabel">内容:</div>
      <el-input
        type="textarea"
        :rows="3"
        v-model.trim="smsContent"
        maxlength="600"
        show-word-limit
        :disabled="template == '' ? false : true"
      >
      </el-input>
    </div>
    <div class="tip">
      <span
        >当前字数：<span style="color: red">{{ count }}</span> /600</span
      >
      <div style="display: inline-block">
        <el-popover placement="bottom-start" width="400" trigger="hover">
          <div class="tipNum" slot="reference">
            计费条数：<span style="color: red">{{ count <= 70 ? 1 : Math.ceil(count / 67) }}</span>
          </div>
          <slot>
            <div>根据工信部统一要求会将短信内容按以下规则拆计费条数：</div>
            <div>短信字数&lt;=70个字数，按照70个字数计一条计费短信；</div>
            <div>短信&gt;70个数，即为长短信，按照67个字数一条计费短信。</div>
            <div>上限是600字，即最多9条。</div>
            <br />
            <div>
              <span
                >由于短信内容可以使用参数，为了确保短信完整性不超上限，所以参数取值计最大数，即带参数的短信计费条数</span
              >
              <span style="font-weight: 700">仅供参考</span>。
            </div>
          </slot>
        </el-popover>
      </div>
    </div>
    <div class="tabRadio">
      <el-radio-group v-model="radio" @change="changeRadio">
        <el-radio :label="0">立即发送</el-radio>
        <el-radio :label="1">定时发送</el-radio>
      </el-radio-group>
      <el-date-picker
        class="dataClass"
        v-if="radio == 1"
        v-model="value1"
        type="datetime"
        placeholder="选择日期时间"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
      </el-date-picker>
    </div>
    <div class="submit2">
      <el-button class="save2" type="primary" @click="sendSms">发送</el-button>
      <el-button class="cancel" type="info" @click="$router.go(-1)">取消</el-button>
    </div>
    <templatemanage
      ref="templatemanageId"
      @getSpinner="getSpinner"
      @setChange="setChange"
    ></templatemanage>
    <newTemplate ref="newTemplateId"></newTemplate>
    <el-dialog
      class="dialogHead"
      title="批量导入"
      :visible.sync="dialogVisibleupload"
      width="600px"
      @closed="closeUpload2"
    >
      <div><span style="color: red">*</span>导入文件</div>
      <div style="margin-left: 100px">
        <div>
          <el-upload
            class="upload-demo"
            multiple
            drag
            ref="upload"
            :headers="header"
            :on-success="uploadSuccess"
            :limit="1"
            :on-exceed="handleExceed"
            :data="upData"
            action="/acb/2.0/memberSmsTask/insertMemberSmsTask"
            :on-change="picChange"
            :file-list="fileList"
            :auto-upload="false"
            :on-remove="removeFile"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">点击上传文件或拖拽上传文件</div>
          </el-upload>
        </div>
        <div>
          <!-- <el-button type="text" @click="exportModel">下载导入模板</el-button> -->
          <el-button type="info" class="cancelDwon" @click="exportModel">下载导入模板</el-button>
        </div>
        <div>(提示：由于接口查询速度问题，建议导入数据量在2000条以内)</div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <div class="submit2">
          <el-button class="save2" type="primary" @click="closeUpload">{{ $t('button.import') }}</el-button>
          <el-button class="cancel" type="info" @click="dialogVisibleupload = false"
            >取消</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import newTemplate from "./components/newTemplate.vue";
import templatemanage from "./components/templatemanage.vue";
import { setIndex, dateFormat, exportExcelNew } from "@/common/js/public.js";
export default {
  data() {
    return {
      labelSMs: "无",
      value1: "",
      header: {
        "Session-Id": sessionStorage.token,
      },
      upData: {
        "Session-Id": sessionStorage.token,
        sendTime: "",
        taskType: "",
        mobiles: "",
        templateId: "",
        content: "",
      },
      fileList: [],
      dialogVisibleupload: false,
      smsIpone: "",
      template: "",
      radio: "",
      smsContent: "",
      loading1: false,
      options: [],
      tag: 0,
      fileName: "",
      smsIndicator: [],
      count: 0,
    };
  },
  provide() {
    return {
      setChangeSon: this.setChange,
      getSpinnerSon: this.getSpinner,
    };
  },
  props: {
    parkType: {
      default: "",
    },
  },
  watch: {
    modelvalue(newVal) {},
    smsContent: {
      handler(val) {
        let sourceLength = 0;
        console.log(this.smsIndicator);
        this.smsIndicator.forEach((item) => {
          if (val.includes(item.source)) {
            const num = val.split(item.source).length - 1;
            sourceLength += (item.source.length - item.length) * num;
          }
        });
        this.count = val.length - sourceLength;
        console.log(this.count, "---", sourceLength);
      },
    },
  },
  components: {
    newTemplate,
    templatemanage,
  },
  methods: {
    closeUpload() {
      if (this.fileName == "") {
        this.$alert("请导入要上传的文件", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      console.log("closeUpload1.fileName", this.fileName);
      this.dialogVisibleupload = false;
      this.tag = 1;
      this.$alert("已导入文件", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
      });
    },
    closeUpload2() {
      console.log("closeUpload1.fileName21", this.fileName);
      if (this.tag == 1) {
        this.tag = 0;
        return;
      }
      this.dialogVisibleupload = false;
      this.$refs.upload.clearFiles();
      this.fileName = "";
      this.picFlag = false;
      console.log("closeUpload2.fileName", this.fileName);
    },
    setChange(value) {
      let obj = {};
      if (value == "") {
        this.smsContent = "";
        this.template = "";
        this.labelSMs = "无";
        return;
      }
      obj = this.options.find(function (item) {
        return item.id === value;
      });
      this.smsContent = obj.content ? obj.content : "";
    },
    changeRadio(pams) {
      console.log("pams", pams);
      if (pams == 0) {
        this.value1 = "";
      }
    },
    sendSms() {
      let timestamp = new Date().getTime();
      let timestamp1 = new Date(this.value1).getTime();
      console.log("timestamp", timestamp);
      console.log("timestamp1", timestamp1);
      if (this.value1) {
        if (timestamp1 < timestamp) {
          this.$alert("时间不能早于当前时间", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
      }
      this.upData.sendTime = this.value1;
      this.upData.taskType = this.radio;
      this.upData.mobiles = this.fileName ? "" : this.smsIpone;
      this.upData.templateId = this.template ? this.template : "";
      this.upData.content = this.template ? "" : this.smsContent;
      this.submitUpload();
    },
    addTemplate() {
      this.$refs.newTemplateId.tabNewTemplate("新建模板");
    },
    templateManage() {
      this.$refs.templatemanageId.closeTempalte();
    },
    // 下拉模板
    getSpinner() {
      console.log("下拉模板");
      this.$axios.get("/acb/2.0/memberSmsTask/getMemberSmsTemplate").then((res) => {
        this.options = res.value;
      });
    },
    // 插入参数查询
    getSmsTarget() {
      this.$axios.get("/acb/2.0/memberSmsTask/getMemberSmsTarget").then((res) => {
        this.smsIndicator = res.value;
      });
    },
    // 导入
    imporT() {
      this.dialogVisibleupload = true;
    },
    submitUpload() {
      if (this.picFlag) {
        this.$refs.upload.submit();
        this.loading1 = true;
      } else {
        // this.$alert("请选择需要上传的文件", this.$t('pop_up.Tips'), {
        //   confirmButtonText: this.$t('pop_up.Determine'),
        // });
        this.$axios
          .post("/acb/2.0/memberSmsTask/insertMemberSmsTask", {
            data: this.upData,
          })
          .then((res) => {
            this.$message({
              type: "info",
              message: "任务发送成功",
            });
            this.$router.go(-1);
          });
      }
    },
    removeFile(file, fileList) {
      console.log("file", file);
      console.log("fileList", fileList);
      this.fileName = "";
    },
    // 下载模板
    exportModel() {
      let opt = {};
      opt = {
        scope: 1,
      };
      // exportExcelNew("/acb/2.0/bar/downloadExcelTemplate", opt);
      exportExcelNew("/acb/2.0/memberSmsTask/downExcelTemplate", opt);
    },
    picChange(file, row) {
      console.log("picChange-》this.fileName1", this.fileName);
      if (file.name && !file.response) {
        this.fileName = file.name;
        this.picFlag = true;
      } else {
        // 上传成功后走这里
        this.$refs.upload.clearFiles();
        this.fileName = "";
        this.picFlag = false;
        console.log("picChange-》else", this.fileName);
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`只能上传一个文件`);
    },
    // 文件上传成功
    uploadSuccess(res) {
      if (res.state == 0) {
        this.$message({
          type: "info",
          message: "发送成功",
        });
        // this.dialogVisibleupload = false;
        // this.pageNum = 1;
        // this.searchData();
        // this.loading1 = false;
        this.$router.go(-1);
      } else {
        this.$alert(res.desc + " 请重新选择文件！！！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        this.loading1 = false;
      }
    },
  },
  mounted() {
    this.getSpinner();
    this.getSmsTarget();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.sendSMS {
  background: #feffff;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  padding: 28px 20px;
}

.sendSMSItem {
  display: flex;
  margin-top: 20px;
}

.sItemLabel {
  width: 70px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #525f7f;
  line-height: 28px;
  margin-right: 10px;
}

.sItemLabel2 {
  width: 70px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #525f7f;
  line-height: 38px;
  margin-right: 8px;
}

.submit {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-left: 78px;
}

.save {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  text-align: center;
  margin-left: 78px;
  margin-top: 10px;
  cursor: pointer;
}

.save2 {
  margin-right: 10px;
}

.cancel {
  margin-left: 10px;
}

.cancelDwon {
  margin-top: 10px;
  margin-bottom: 10px;
}

.tabRadio {
  padding-left: 78px;
  margin-top: 20px;
}

.submit2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tip {
  float: right;
  margin-top: 20px;
  color: #999;
}
.tipNum {
  margin-left: 5px;
  display: inline;
  text-decoration: underline;
}

>>> .dialogHead .el-dialog .el-dialog__header {
  padding: 13px 13px 13px 30px !important;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #2F2F51;
}

>>> .el-dialog__title {
  color: #2F2F51;
  font-size: 14px;
}

>>> .el-dialog__title:before {
  content: '';
  width: 4px;
  height: 15px;
  background: linear-gradient(225deg, #4AA1FA 0%, #2468F2 100%);
  border-radius: 1px;
  position: absolute;
  top: 17px;
  left: 20px;
}

>>> .el-progress--line {
  display: none !important;
}

>>> .el-progress__text {
  display: none;
}

>>> .el-dialog__headerbtn {
  position: absolute;
  top: 12px;
  right: 20px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

.dataClass {
  margin-left: 20px;
}
</style>
<style scoped>
.sendSMSItem >>> .el-input__count {
  opacity: 0;
}
</style>
