<template>
  <div ref="newTemplate" class="newTemplateMain" v-if="closeTempalteTag">
    <div class="title">
      <span class="titleIcon"></span> <span class="titleContent">模板管理</span>
      <img src="./close.png" class="closeIcon" alt="" @click="closeTempalte" />
    </div>
    <div class="search">
      <el-autocomplete
        class="autocomplete"
        v-model="modelvalue"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入内容"
        value-key="templateName"
        :trigger-on-focus="false"
        @select="handleSelect"
        clearable
      ></el-autocomplete>
      <el-button class="newTemplatemanege" type="primary" @click="addTemplate">新建模板</el-button>
    </div>
    <el-table
      header-cell-class-name="header-call-style"
      border
      :data="tableData"
      style="width: 100%"
      height="350"
    >
      <el-table-column
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        v-for="item in tableCols"
        :key="item.prop"
        :formatter="item.formatter"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="80">
        <template slot-scope="scope">
          <span @click="deleteItem(scope.row)" class="deteleItem" v-if="scope.row.id != 1"
            >删除</span
          >
          <span @click="upDataItem(scope.row)" class="deteleItem" v-if="scope.row.id != 1"
            >修改</span
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    ></el-pagination> -->
    <newTemplate ref="newTemplateId" @getSmsAllList="getSmsAllList"></newTemplate>
  </div>
</template>
<script>
import newTemplate from "./newTemplate.vue";
export default {
  data() {
    return {
      total: 0,
      pageSize: 5,
      page: 1,
      templateid: "",
      closeTempalteTag: false,
      modelvalue: "",
      tableData: [],
      tableCols: [
        {
          prop: "templateName",
          label: "模板名称",
          width: "",
        },
        {
          prop: "content",
          label: "模板内容",
          width: "250",
        },
        {
          prop: "operatorName",
          label: "创建人",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_time"),
        },
      ],
    };
  },
  props: {
    parkType: {
      default: "",
    },
  },
  watch: {
    modelvalue(newVal) {
      console.log("newVal", newVal);
      if (newVal.length == 0) {
        this.getSmsAllList();
      }
    },
  },
  methods: {
    upDataItem(data) {
      console.log("data", data);
      this.$refs.newTemplateId.updata(data);
    },
    deleteItem(data) {
      this.$alert("确定要删除吗？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/memberSmsTask/delTemplate", {
              data: {
                id: data.id,
              },
            })
            .then((res) => {
              if (res.state === 0) {
                this.$message({
                  message: "删除成功！",
                  type: "success",
                });
                this.getSmsAllList();
                this.$emit("getSpinner");
                this.$emit("setChange", "");
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getSmsList() {
      this.$axios
        .get("/acb/2.0/memberSmsTask/getMemberSmsTemplate", {
          data: {
            id: this.templateid,
          },
        })
        .then((res) => {
          console.log("res", res);
          this.tableData = res.value;
        });
    },
    addTemplate() {
      this.$refs.newTemplateId.tabNewTemplate();
    },
    closeTempalte() {
      if (this.closeTempalteTag) {
        this.closeTempalteTag = false;
        this.modelvalue = "";
      } else {
        this.closeTempalteTag = true;
        this.getSmsAllList();
      }
    },
    getSmsAllList() {
      this.$axios
        .get("/acb/2.0/memberSmsTask/getMemberSmsTemplate", {
          data: {},
        })
        .then((res) => {
          console.log("res", res);
          this.tableData = res.value;
          this.total = res.value.length;
        });
    },
    querySearchAsync(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/memberSmsTask/getMemberSmsTemplate";
      this.$axios
        .get(url, {
          data: {
            templateName: queryString,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      console.log("item", item);
      this.templateid = item.id;
      this.getSmsList();
    },
  },
  mounted() {},
  components: {
    newTemplate,
  },
};
</script>
<style scoped>
.newTemplateMain {
  width: 690px;
  /* height: 430px; */
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: absolute;
  top: calc(50% - 210px);
  left: calc(50% - 345px);
  padding: 20px;
  z-index: 99;
}
.titleIcon {
  width: 4px;
  height: 15px;
  background: linear-gradient(225deg, #4aa1fa 0%, #2468f2 100%);
  border-radius: 1px;
  display: inline-block;
  margin-right: 4px;
}
.titleContent {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #2f2f51;
  display: inline-block;
  transform: translateY(-2px);
}
.closeIcon {
  width: 16px;
  height: 16px;
  float: right;
}
.autocomplete {
  width: 80%;
}
.newTemplatemanege {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  margin-left: 20px;
}
.search {
  margin-top: 20px;
  margin-bottom: 20px;
}
.deteleItem {
  color: #527bff;
  margin-right: 10px;
  cursor: pointer;
}
</style>
