<template>
  <div ref="newTemplate" class="newTemplateMain" v-if="newTemplate">
    <div class="title">
      <span class="titleIcon"></span>
      <span class="titleContent">{{ titleheader }}</span>
      <img src="./close.png" class="closeIcon" @click="tabNewTemplate" alt="" />
    </div>
    <div class="templateNameContent">
      <div class="templateMain">
        <span class="redTips">*</span><span class="templateName">模板名称</span>
      </div>
      <el-input
        type="text"
        placeholder="请输入内容"
        v-model.trim="templateName"
        maxlength="20"
        show-word-limit
      >
      </el-input>
    </div>
    <div class="templateNameContent">
      <div class="templateMain">
        <span class="redTips">*</span><span class="templateName">描述</span>
      </div>
      <div class="describe">
        <span class="describeTitle">插入参数：</span>
        <span
          class="pattms"
          v-for="(value, index) in smsIndicator"
          :key="index"
          @click="insertVal(value.source)"
          >${{ value.targetDesc }} <span class="stick"></span
        ></span>
      </div>
      <!-- <el-input
        type="textarea"
        :rows="6"
        placeholder="支持插入参数，参数按手机号匹配，请保持参"
        v-model="describe"
      >
      </el-input> -->
      <el-input
        @blur="campaignNameBlur"
        type="textarea"
        :maxlength="600 + count[1]"
        show-word-limit
        placeholder="支持插入参数，参数按手机号匹配，请保持参数完整性请勿更改"
        @keydown.delete.native="del"
        :autosize="{ minRows: 6, maxRows: 10 }"
        v-model.trim="describe"
        ref="input"
        id="textarea"
        @keyup.native="keyUp()"
        @click.native="keyUp()"
      >
      </el-input>
      <div class="tip">
        <span
          >当前字数：<span style="color: red" v-if="count[0]">{{ count[0] }}</span>
          <span v-else>0</span>/600</span
        >
        <div style="display: inline-block">
          <el-popover placement="bottom-start" width="400" trigger="hover">
            <div class="tipNum" slot="reference">
              计费条数：<span style="color: red">{{
                count[0] <= 70 ? 1 : Math.ceil(count[0] / 67)
              }}</span>
            </div>
            <slot>
              <div>根据工信部统一要求会将短信内容按以下规则拆计费条数：</div>
              <div>短信字数&lt;=70个字数，按照70个字数计一条计费短信；</div>
              <div>短信&gt;70个数，即为长短信，按照67个字数一条计费短信。</div>
              <div>上限是600字，即最多9条。</div>
              <br />
              <div>
                <span
                  >由于短信内容可以使用参数，为了确保短信完整性不超上限，所以参数取值计最大数，即带参数的短信计费条数</span
                >
                <span style="font-weight: 700">仅供参考</span>。
              </div>
            </slot>
          </el-popover>
        </div>
      </div>
      <div class="submit">
        <div class="save" @click="saveSubmit">保存</div>
        <div class="cancel" @click="tabNewTemplate">取消</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      titleheader: "新建模板",
      templateName: "",
      describe: "",
      newTemplate: false,
      smsIndicator: [],
      cursorPosition: "", // 保存光标位置
      labelSuffix: {
        propList: [],
      },
      updataId: "",
    };
  },
  props: {
    parkType: {
      default: "",
    },
  },
  inject: ["setChangeSon", "getSpinnerSon"],
  watch: {
    modelvalue(newVal) {},
  },
  computed: {
    // 计算字数
    count() {
      let sourceLength = 0;
      this.smsIndicator.forEach((item) => {
        if (this.describe.includes(item.source)) {
          const num = this.describe.split(item.source).length - 1;
          sourceLength += (item.source.length - item.length) * num;
        }
      });
      return [this.describe.length - sourceLength, sourceLength];
    },
  },
  methods: {
    updata(data) {
      this.describe = data.content;
      this.templateName = data.templateName;
      this.updataId = data.id;
      this.tabNewTemplate();
      this.titleheader = "修改模板";
    },
    saveSubmit() {
      if (this.describe.length <= 0) {
        this.$message("描述不能为空");
        // eslint-disable-next-line no-useless-return
        return;
      }
      if (this.templateName.length <= 0) {
        this.$message("模板名称不能为空");
        // eslint-disable-next-line no-useless-return
        return;
      }
      this.saveSubmitSms();
    },
    // 发送短信
    saveSubmitSms() {
      // 修改成功
      if (this.updataId) {
        this.$axios
          .post(
            "/acb/2.0/memberSmsTask/updateTemplate",
            {
              data: {
                templateName: this.templateName,
                content: this.describe,
                id: this.updataId,
              },
            },
            {
              headers: {
                "Content-Type": "application/json;",
              },
            }
          )
          .then((res) => {
            this.$alert("修改成功", {
              callback: (action) => {
                this.$emit("getSmsAllList");
                this.getSpinnerSon();
                this.setChangeSon("");
                this.tabNewTemplate();
              },
            });
          });
      } else {
        this.$axios
          .post(
            "/acb/2.0/memberSmsTask/insertSmsTemplate",
            {
              data: {
                templateName: this.templateName,
                content: this.describe,
              },
            },
            {
              headers: {
                "Content-Type": "application/json;",
              },
            }
          )
          .then((res) => {
            this.$alert("保存成功", {
              callback: (action) => {
                this.$emit("getSmsAllList");
                this.getSpinnerSon();
                this.tabNewTemplate();
                this.templateName = "";
                this.describe = "";
              },
            });
          });
      }
    },
    // 失去焦点时保存光标位置
    campaignNameBlur(e) {
      this.cursorPosition = e.srcElement.selectionStart;
    },
    // 插入内容
    insertVal(val) {
      let num = this.cursorPosition;
      let type = typeof num;
      let cont = this.describe;
      if (type == "number") {
        // 插入到指定光标处
        let left = cont.slice(0, num);
        let right = cont.slice(num);
        this.$nextTick(() => {
          this.describe = left + val + right;
        });
      } else {
        // 没有指定插入直接添加到最后
        this.$nextTick(() => {
          this.describe += val;
        });
      }
      // 自动获取焦点
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    // del(e) {
    //   let content = this.describe;
    //   if (!content) return; // 没有内容就不用进行后面操作
    //   let start = e.target.selectionStart; // 光标起始位置
    //   let end = e.target.selectionEnd; // 光标结束位置
    //   if (start == end) {
    //     // 删除操作判断，
    //     let left = content.slice(end - 6, end);
    //     let right = content.slice(end - 1, end + 5); // 因为我们的变量长度都为6，所以截取左右长度为6的字符串;
    //     let arr = this.labelSuffix.propList.map((item) => item.code);
    //     if (arr.includes(left)) {
    //       // 判断变量中是否存在，存在则设置光标位置，不存在则不用管
    //       e.target.setSelectionRange(end - 6, end); // 设置光标位置
    //       e.preventDefault(); // 阻止浏览器的默认行为，防止删除
    //     } else if (arr.includes(right)) {
    //       e.target.setSelectionRange(end - 1, end + 5);
    //       e.preventDefault();
    //     }
    //   }
    // },
    del(e) {
      // 只能处理参数在首尾删除的情况
      let content = this.describe;
      const list = this.labelSuffix.propList;
      if (!content) return; // 没有内容就不用进行后面操作
      let start = e.target.selectionStart; // 光标起始位置
      let end = e.target.selectionEnd; // 光标结束位置
      if (start == end) {
        // 循环遍历查找被删除的参数
        list.forEach((item) => {
          let left = content.slice(end - item.length, end);
          let right = content.slice(end - 1, end + item.length - 1);
          if (list.includes(left)) {
            e.target.setSelectionRange(end - item.length, end);
            e.preventDefault();
          }
          if (list.includes(right)) {
            e.target.setSelectionRange(end - 1, end + item.length - 1);
            e.preventDefault();
          }
        });
      }
    },
    // 保证参数的完整性，光标在参数之间，移动光标到参数后
    keyUp() {
      const { selectionStart, selectionEnd } = this.$refs.input.$el.children[0]; // 获取光标位置
      let content = this.describe;
      this.labelSuffix.propList.forEach((item) => {
        const replaced = item.replace("{", "\\{").replace("}", "\\}").replace("$", "\\$");
        const pat = new RegExp(`${replaced}`, "gi");
        let list = [];
        let result = null;
        // eslint-disable-next-line no-cond-assign
        while ((result = pat.exec(content))) {
          list.push(result.index);
        }
        list.forEach((i) => {
          if (
            (selectionStart > i && selectionStart < i + item.length) ||
            (selectionEnd > i && selectionEnd < i + item.length)
          ) {
            this.$refs.input.$el.children[0].selectionStart = i + item.length; // 设置光标位置
          }
        });
      });
    },
    tabNewTemplate(titleheader) {
      if (this.newTemplate) {
        this.newTemplate = false;
        this.titleheader = titleheader;
      } else {
        this.newTemplate = true;
        this.titleheader = "新建模板";
        this.getSmsTarget();
        // this.templateName = "";
        // this.describe = "";
      }
    },
    // 插入参数查询
    getSmsTarget() {
      this.$axios.get("/acb/2.0/memberSmsTask/getMemberSmsTarget").then((res) => {
        this.smsIndicator = res.value;
        this.labelSuffix.propList = res.value.map((item) => item.source);
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.newTemplateMain {
  width: 690px;
  /* height: 430px; */
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: absolute;
  top: calc(50% - 210px);
  left: calc(50% - 345px);
  padding: 20px;
  z-index: 99;
}
.titleIcon {
  width: 4px;
  height: 15px;
  background: linear-gradient(225deg, #4aa1fa 0%, #2468f2 100%);
  border-radius: 1px;
  display: inline-block;
  margin-right: 4px;
}
.titleContent {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #2f2f51;
  display: inline-block;
  transform: translateY(-2px);
}
.closeIcon {
  width: 16px;
  height: 16px;
  float: right;
}
.templateNameContent {
  margin-top: 21px;
}
.templateMain {
  margin-bottom: 9px;
}
.redTips {
  color: #f56c6c;
}
.templateName {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #525f7f;
}
.describe {
  height: 36px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 1px solid #e9edf0;
  border-left: 1px solid #e9edf0;
  border-right: 1px solid #e9edf0;
  padding-top: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}
.describeTitle {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #525f7f;
}
.pattms {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #527bff;
  cursor: pointer;
}
.stick {
  display: inline-block;
  width: 1px;
  height: 10px;
  background: #c9cfd4;
  margin-left: 10px;
  margin-right: 10px;
}
.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 60px;
}
.save {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  text-align: center;
  margin-right: 10px;
}
.cancel {
  margin-left: 10px;
}
.tip {
  float: right;
  margin-top: 20px;
  color: #999;
}
.tipNum {
  margin-left: 5px;
  display: inline;
  text-decoration: underline;
}
</style>
<style>
.newTemplateMain .el-textarea__inner {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>
<style scoped>
.templateNameContent >>> .el-input__count {
  opacity: 0;
}
</style>
